import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './redux-store.js';
import ErrorBoundary from './components/error handle/Error_Boundary.jsx';
import SiteConfig from './config.js';
import PortalApp from './PortalApp.jsx';
import PatientRoutes from './pages/patient/patient.routes.jsx';
import OperationRoutes from './pages/operation/operation.routes.jsx';
import ExpertRoutes from './pages/expert/expert.routes.jsx';
import LayoutDir from './layouts/LayoutDir.jsx';

const role = import.meta.env.VITE_PORTAL_ROLE;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <LayoutDir>
      <ErrorBoundary>
        <Provider store={store}>
          {<SiteConfig /> /* site configuration */}
          {
            /* checks on the portal type, if its patient then patient urls only provided, else if expert then expert urls only provided, else the both urls is provided. the portal type is provided in the .env file */
            role == 'patient' ? (
              <PortalApp role={role}>
                <PatientRoutes />
              </PortalApp>
            ) : role == 'expert' ? (
              <PortalApp role={role}>
                <ExpertRoutes />
              </PortalApp>
            ) : role == 'operation' ? (
              <PortalApp role={role}>
                <OperationRoutes />
              </PortalApp>
            ) : (
              <App />
            )
          }
        </Provider>
      </ErrorBoundary>
    </LayoutDir>
  </BrowserRouter>
);
